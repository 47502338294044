








































































import { Component, Vue } from 'vue-property-decorator'
import { GameProvider, Link } from '@/components'
import BoardGameLogo from './assets/board-game.svg'

@Component({
  components: {
    Link,
    GameProvider,
    BoardGameLogo
  }
})
export default class App extends Vue {}
