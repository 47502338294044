















import { Component, Vue } from 'vue-property-decorator'
import NotFoundImage from '@/assets/not-found.svg'
import Panel from './Panel.vue'
import ImageMessage from './ImageMessage.vue'

@Component({
  components: {
    Panel,
    ImageMessage,
    NotFoundImage
  }
})
export default class EmptySearch extends Vue {}
