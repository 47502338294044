var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n    border-solid\n    border-2\n    rounded-md\n    bg-opacity-80\n    shadow-md\n  ",class:[
    _vm.classes,
    {
      'border-red-500': _vm.isError,
      'bg-red-100': _vm.isError,
      'border-yellow-300': _vm.isWarning,
      'bg-yellow-50': _vm.isWarning,
      'border-gray-200': _vm.isDefault,
      'bg-gray-50': _vm.isDefault
    }
  ]},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }