















import { Component, Vue } from 'vue-property-decorator'
import DataErrorImage from '@/assets/data-error.svg'
import Panel from './Panel.vue'
import ImageMessage from './ImageMessage.vue'

@Component({
  components: {
    Panel,
    DataErrorImage,
    ImageMessage
  }
})
export default class GameDataError extends Vue {}
