

























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class Panel extends Vue {
  @Prop() classes?: string
  @Prop({ default: false }) isError!: boolean
  @Prop({ default: false }) isWarning!: boolean

  get isDefault () {
    return !this.isError && !this.isWarning
  }
}
