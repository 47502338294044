












import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class IconText extends Vue {
  @Prop() readonly tooltip?: string | undefined
}
