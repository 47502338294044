
































import { Meta } from '@sophosoft/vue-meta-decorator'
import { Component, Vue } from 'vue-property-decorator'
import { Panel } from '@/components'
import CobwebImage from '@/assets/cobweb.svg'

@Component({
  components: {
    Panel,
    CobwebImage
  }
})
export default class NotFoundPage extends Vue {
  @Meta
  getMetaInfo () {
    return {
      title: 'Page Not Found - Josh\'s Games'
    }
  }
}
