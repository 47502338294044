






















import { Component, Vue } from 'vue-property-decorator'
import Panel from './Panel.vue'
import ImageMessage from './ImageMessage.vue'
import BoxImage from '@/assets/box.svg'

@Component({
  components: {
    Panel,
    BoxImage,
    ImageMessage
  }
})
export default class GameNotFound extends Vue {
}
