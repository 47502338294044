














































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Game } from '@/models'
import GroupImage from '@/assets/group.svg'
import HourglassImage from '@/assets/hourglass.svg'
import BrainImage from '@/assets/brain.svg'
import ReviewImage from '@/assets/review.svg'
import { ExternalLinkIcon } from './icons'
import Panel from './Panel.vue'
import RibbonBar from './RibbonBar.vue'
import IconText from './IconText.vue'

@Component({
  components: {
    Panel,
    RibbonBar,
    GroupImage,
    HourglassImage,
    ReviewImage,
    IconText,
    BrainImage,
    ExternalLinkIcon
  }
})
export default class GamePage extends Vue {
  @Prop() game!: Game

  get fullName (): string {
    let name = this.game.name
    if (this.game.subTitle) {
      name = `${name}: ${this.game.subTitle}`
    }

    if (this.game.editionTitle) {
      name = `${name} (${this.game.editionTitle})`
    }

    return name
  }

  get playerRange (): string {
    if (this.game.minPlayers === this.game.maxPlayers) {
      return `${this.game.minPlayers}`
    }

    return `${this.game.minPlayers}-${this.game.maxPlayers}`
  }

  buildComplexity (weight: number): string {
    if (!weight) {
      return 'N/A'
    }

    return `${weight.toFixed(1)}/5`
  }
}
