
















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { VueClazyLoad } from 'vue-clazy-load'
import { Location } from 'vue-router'
import { Game } from '@/models/game'
import RibbonBar from './RibbonBar.vue'
import Panel from './Panel.vue'
import BlurHash from './BlurHash.vue'

@Component({
  components: {
    ClazyLoad: VueClazyLoad,
    RibbonBar,
    Panel,
    BlurHash
  }
})
export default class GameCard extends Vue {
  @Prop() readonly game!: Game

  get link (): Location {
    return {
      name: 'Game',
      params: { id: `${this.game.bggId}`, name: this.game.slug }
    }
  }
}
