













































import { Component, Prop, Vue } from 'vue-property-decorator'

export interface SelectOption {
  name: string;
  value: string;
}

@Component({})
export default class Select extends Vue {
  @Prop({ default: '' })
  label!: string;

  @Prop({ default: () => [] })
  options!: ReadonlyArray<SelectOption>;

  @Prop({ default: '' })
  value!: string;

  private handleChange (e: Event) {
    const select = e.target as HTMLSelectElement
    const value = select.options[select.selectedIndex].value

    this.$emit('onChange', value)
  }
}
