




















































import { Component, Prop, Vue } from 'vue-property-decorator'
import StarIcon from '@/assets/star.svg'
import HeartIcon from '@/assets/heart.svg'
import NewIcon from '@/assets/new.svg'
import { Game } from '@/models'
import Ribbon from './Ribbon.vue'

@Component({
  components: {
    StarIcon,
    HeartIcon,
    NewIcon,
    Ribbon
  }
})
export default class RibbonBar extends Vue {
  @Prop() readonly game!: Game

  get isFavorite () {
    return this.game.tags.includes('fav')
  }

  get isWifeFavorite () {
    return this.game.tags.includes('w-fav')
  }

  get isNew () {
    return this.game.new
  }

  get isForSale () {
    return this.game.forSale
  }
}
