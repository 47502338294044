









































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class Ribbon extends Vue {
  @Prop() readonly coloring!: string
  @Prop() readonly tooltip!: string
}
