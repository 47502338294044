























































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class Toggle extends Vue {
  @Prop({ default: false })
  value!: boolean;

  @Prop({ default: '' })
  label!: string;

  @Prop()
  unselectedClass?: string;

  @Prop()
  selectedClass?: string;

  handleClick () {
    this.$emit('onChange', !this.value)
  }
}
